














































import { Component, Prop, Emit, Vue, PropSync } from 'vue-property-decorator';
import { TableMode } from '@/types';

type TMSTableMode = TableMode;

@Component({
  components: {
    
  }
})
export default class TableOptionsShelf extends Vue {
  @PropSync('table_mode', { type: String }) table_mode_synced!: TMSTableMode;

  changeTableMode(table_mode: TMSTableMode): void {
    this.table_mode_synced = table_mode;
    this.$emit('table_mode', this.table_mode_synced);
  }
}
