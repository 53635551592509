













































































import { Component, Prop, Emit, Vue, PropSync } from 'vue-property-decorator';
import { TableMode, SortOrder, SortParams } from '@/types';
import { VOCABULARY_SORT_COLUMN } from '@/enums';

type TMSTableMode = TableMode;
type VSortParams = SortParams;

type SelectionOption = {id: VOCABULARY_SORT_COLUMN, title: string, disabled: boolean};

@Component({
  components: {
    
  }
})
export default class SortingOptionsShelf extends Vue {
  @Prop({ default: 'normal' }) table_mode!: TMSTableMode;
  //@Prop() sort_params!: VSortParams;
  @PropSync('sort_params', { type: Object }) sort_params_synced!: VSortParams;
  public sorting_options: Array<SelectionOption> = [
    {id:VOCABULARY_SORT_COLUMN.DATE_CREATED, title:'Date Added', disabled: false},
    {id:VOCABULARY_SORT_COLUMN.CHINESE, title:'Chinese', disabled: false},
    {id:VOCABULARY_SORT_COLUMN.PINYIN, title:'Pinyin', disabled: false},
    {id:VOCABULARY_SORT_COLUMN.ENGLISH, title:'English', disabled: false},
  ];

  public columnIDToTitle(column_id: number): string {
    for(let i=0; i<this.sorting_options.length; i++) {
      if(this.sorting_options[i].id == column_id) {
        return this.sorting_options[i].title;
      }
    }
    return "";
  }

/*
  @Emit()
  onclick(event: Event): Event {
    return event;
  }
*/
  /*
  @Emit()
  sortParamsChanged(): VSortParams {
    return {
        column: this.sort_column,
        order: this.order
    };
  }
  */

  selectOption(option: SelectionOption): void {
    this.sort_params_synced.column = option.id;
    this.$emit('sort_params', this.sort_params_synced);
  }

  toggleOrder(): void {
    if(this.sort_params_synced.order == "asc") {
      this.sort_params_synced.order = "desc";
    } else {
      this.sort_params_synced.order = "asc";
    }
    this.$emit('sort_params', this.sort_params_synced);
  }
}
