




































import { Component, Prop, Emit, Vue, PropSync, Watch } from 'vue-property-decorator';
import { VOCABULARY_SORT_COLUMN, VOCABULARY_TEXT_SEARCH_COLUMN } from '@/enums';
import { SearchParams, VocabularyManager, VocabularyEvent, Nullable, SearchMode } from '@/types';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';


@Component({
  components: {
    
  }
})
export default class SearchOptionsMenu extends Vue {
  @Prop({ default: 'simple' }) search_mode!: string;
  public search_help_menu: boolean = false;
}
