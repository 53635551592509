































































































































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import TagsComboBox from '@/components/shared/TagsComboBox.vue';
import VocabularyTableNormal from '@/components/shared/VocabularyTableNormal.vue';
import VocabularyEditComponent from '@/components/shared/VocabularyEditComponent.vue';
import VocabularyTableCompact from '@/components/shared/VocabularyTableCompact.vue';
//import VocabularyTableNormal2 from '@/components/shared/VocabularyTableNormal2.vue';
import VocabularyTableQuickStudy from '@/components/shared/VocabularyTableQuickStudy.vue';
import ToogleIconWithTooltip from '@/components/shared/ToogleIconWithTooltip.vue';
import TableOptionsShelf from '@/components/shared/TableOptionsShelf.vue';
import SortingOptionsShelf from '@/components/shared/SortingOptionsShelf.vue';
import SelectionOptionsMenuButton from '@/components/shared/SelectionOptionsMenuButton.vue';
import TablePaginator from '@/components/shared/TablePaginator.vue';
import TableSettings from '@/components/shared/TableSettings.vue';
import AdvancedSearch from '@/components/shared/AdvancedSearch.vue';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { VocabularyService, MessageDispatcher, NavRouter, ModuleProvider } from '@/services';
import AppMain from '@/AppMain';
import { take, first, share, mapTo, takeUntil, startWith, distinctUntilChanged, filter, delay, throttleTime, throttle, debounceTime, switchMap, tap, multicast, withLatestFrom } from 'rxjs/operators';
import { from, Observable, merge, timer, combineLatest, Subject, asyncScheduler, concat, of, Subscription } from 'rxjs';
import { AppStage, VocabularyTableRow, PaginateOperation, TableMode, Nullable, VocabularyManager, SortParams, PaginateParams, Vocabulary, VocabularyState, VocabularyEditor } from '@/types';
import { VocabularyPageResult, VocabularyEvent, PageSize, VocabularyDeleteRequest, SearchMode, SearchParams, VocabularyStatus } from '@/types';
import { ComponentID, Tag, Liebiao } from '@/types';
import { generateComponentID } from '@/helpers/helpers';
import { VocabularyQuery } from '@/classes/VocabularyQuery';
import TagChip from '@/components/shared/TagChip.vue';
import { InputValidationRule } from 'vuetify';
import { SHENGCI_MODULES, VOCABULARY_SELECTION_OPTION, VOCABULARY_TEXT_SEARCH_COLUMN } from '@/enums';
import { VocabularyQueryParams } from '@/classes/VocabularyQueryParams';
import { AppNotification } from '@/classes/AppNotification';
import { log } from '@/helpers/logger';
import DeleteVocabularyMenu from '@/components/shared/DeleteVocabularyMenu.vue';
import SearchOptionsMenu from '@/components/shared/SearchOptionsMenu.vue';
import { Route } from 'vue-router';

/**
   *  TODO: if only one english entry, dont need numbers
   *  TODO: table views need to be cached
   *  TODO: display tags in the order they were added
   *  TODO: show only words, show only sentences
   *  TODO: back to top button needs to show only after table is mounted
   *  TODO: pressing enter in the query bar will force re-query. This is needed if the current query goes stale
   *  TODO: dont show a loader during waiting for login, show a message or form or something!
   *  TODO: test secure access to audio files
   *  TODO: a temporary network disconnection should not trigger a query_id renewal
   *  TODO: temporary network disconnections should not result in user data loss? might be more effort then its worth, or maybe a future update
   *  TODO: make a route that always returns an debug error. Use this route to test if debug errors are leaked in production
   *  TODO: uninstall command: clean up audio, delete db, etc.
   *  TODO: if there is an error in the server, dont just leave table loading. show an error message
   *  TODO: disable form controls when a global selection event, refresh, etc. is pending
   *  TODO: get page_size changes to approximate the current page as best as possible
   *  TODO: cron job to delete expired quiz data
   *  TODO: feature: remove tags from all vocabulary (or simply delete and recreate tag?)
   *  TODO: increase rate limit for vocabulary queries
   *  TODO: entry sentences limit is 2000. maybe too much
   */

type VocabTableMode = TableMode;
type VocabularyListMode = "table" | "new" | "edit";
type VSortParams = SortParams;
type SLiebiao = Liebiao;

@Component({
  components: {
    AsyncButton, SearchBox, TagsComboBox, ToogleIconWithTooltip, TagChip, VocabularyTableNormal,
    VocabularyTableQuickStudy, TableOptionsShelf, TablePaginator, SortingOptionsShelf,
    VocabularyTableCompact, VocabularyEditComponent,
    TableSettings, SelectionOptionsMenuButton, DeleteVocabularyMenu, AdvancedSearch, SearchOptionsMenu
  }
})
export default class VocabularyListComponent extends Vue implements VocabularyManager
{  
  @lazyInject(TYPES.VOCABULARYSERVICE_INSTANCE)
  private vocabulary_service!: VocabularyService;

  @lazyInject(TYPES.MESSAGEDISPATCHER_INSTANCE)
  private message_dispatcher!: MessageDispatcher;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @Prop() liebiao!: SLiebiao;

  public search: string = "";
  public vocabulary_row_list: Array<VocabularyTableRow> = new Array<VocabularyTableRow>();
  public table_mode: VocabTableMode = "normal";
  public reverse_study_mode: boolean = false;
  public selection_box: boolean = false;
  public vocab_list_mode: VocabularyListMode = "table";
  public table_loading: boolean = false;
  public vocabulary_total: number = 0;
  public table_query_params: VocabularyQueryParams = new VocabularyQueryParams();
  public table_search_params: SearchParams = {
    text: "",
    mode: "simple",
    search_column: VOCABULARY_TEXT_SEARCH_COLUMN.CHINESE,
    regexp: false,
    date_to: null,
    date_from: null,
    tags_include: new Array<Tag>(),
    tags_exclude: new Array<Tag>(),
    liebiaos: new Array<Liebiao>(),
    statuses: new Array<VocabularyStatus>(),
  };
  public last_table_search_params: SearchParams = {
    text: "",
    mode: "simple",
    search_column: VOCABULARY_TEXT_SEARCH_COLUMN.CHINESE,
    regexp: false,
    date_to: null,
    date_from: null,
    tags_include: new Array<Tag>(),
    tags_exclude: new Array<Tag>(),
    liebiaos: new Array<Liebiao>(),
    statuses: new Array<VocabularyStatus>(),
  };

  public last_table_query_params: Nullable<VocabularyQueryParams> = null;
  public query_requests$: Subject<VocabularyQueryParams> = new Subject<VocabularyQueryParams>();
  public query_results_subscription: Subscription = new Subscription();
  public vocabulary_event_subscription: Subscription = new Subscription();
  private delay_before_loader: number = 600;
  private min_loader_duration: number = 1000;
  private vocabulary_taken: number = 0;
  private vocabulary_query!: VocabularyQuery;
  public local_selection_count: number = 0;
  public global_selection_count: number = 0;
  public formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US');
  public selection_count_loading: boolean = false;
  public add_vocabulary_open: boolean = false;
  public pending_delete: boolean = false;
  public is_refreshing: boolean = false;
  public pending_advanced_search: boolean = false;
  public id: ComponentID = generateComponentID();
  public loader_subscription$: Nullable<Subscription> = null;
  public table_top_visible: boolean = false;
  public this_component: VocabularyManager = this;
  public debug_time: number = 9;

  public makeToast(): void {
    console.log('makeToast');
    /*
    this.message_dispatcher.pushNotification(new AppNotification(
        "Vocabulary updated from another device or tab",
        "toast",
        null, null,
        "vocabulary queryID changed"
    ));
    */
    //if(this.vocabulary_query) this.vocabulary_query.testNotification();
    //else console.log('no vocabulary query');
  }

  public onSearchModeChange(search_mode: SearchMode): void {
    this.table_search_params.mode = search_mode;
  }

  public getSelectionCountTitle(): string {
    return `${this.formatter.format(this.global_selection_count)}`;
  }

  private resetTableQuery(): void {
    //console.log('resetTableQuery');
    this.is_refreshing = false;
    this.pending_advanced_search = false;
    this.table_query_params.cleanParams();
  }

  public refreshVocabulary(trigger_loader: boolean): void {
    this.is_refreshing = true;
    //const refresh_query_params: VocabularyQueryParams = this.table_query_params.clone()
    //refresh_query_params.is_refresh = true;
    //refresh_query_params.trigger_loader = trigger_loader;
    //this.issueQuery(refresh_query_params);
    this.table_query_params.is_refresh = true;
    this.table_query_params.trigger_loader = true;
    this.table_query_params.addStateStoreRequest({query_id: this.vocabulary_query.query_id, none:true});
    this.issueQuery(this.table_query_params);
    this.vocabulary_service.broadcastVocabularyEvent({
      owner: this,
      type: "refreshing"
    });
  }

  changeSelectionOption(option: VOCABULARY_SELECTION_OPTION): void {
    switch(option) {
      case VOCABULARY_SELECTION_OPTION.SELECT_ALL:
        this.selection_count_loading = true;
        this.selection_box = true;
        this.local_selection_count = this.vocabulary_row_list.length;
        this.vocabulary_query.storeVocabularySelectionState({
          'query_id': this.vocabulary_query.query_id,
          'all': true
        }).then( () => {
          this.selection_count_loading = false;
          this.global_selection_count = this.vocabulary_total;
          //this.vocabulary_query.hasEditedSelection(true);
          console.log('all selected!');
        }).catch( (error) => {
          console.log('selection error')
          console.log(error);
        });
        break;
      case VOCABULARY_SELECTION_OPTION.SELECT_NONE:
        if(this.global_selection_count == 0) return;
        this.selection_count_loading = true;
        this.selection_box = false;
        this.local_selection_count = 0;
        this.vocabulary_query.storeVocabularySelectionState({
          'query_id': this.vocabulary_query.query_id,
          'none': true
        }).then( () => {
          this.selection_count_loading = false;
          this.global_selection_count = 0;
          //this.vocabulary_query.hasEditedSelection(false);
          console.log('none selected!');
        }).catch( (error) => {
          console.log('selection error')
          console.log(error);
        });
        break;
    }
  }

  changedRowSelectionBox(vocabulary_row: VocabularyTableRow): void {
    this.vocabulary_query.hasEditedSelection(this.table_query_params.paginate_params.page);
    const selection_increment = vocabulary_row.vocabulary_state.selected ? 1 : -1;
    this.global_selection_count += selection_increment;
    this.local_selection_count += selection_increment;
    this.local_selection_count = Math.max(0, this.local_selection_count);
    this.local_selection_count = Math.min(this.vocabulary_row_list.length, this.local_selection_count);
    if(this.local_selection_count == this.vocabulary_row_list.length) {
      this.selection_box = true;
    } else {
      this.selection_box = false;
    }
  }

  startVocabularyIndex(): number {
    return this.table_query_params.paginate_params.page * this.table_query_params.paginate_params.page_size + 1;
  }

  endVocabularyIndex(): number {
    return Math.min(this.startVocabularyIndex() + this.vocabulary_taken - 1, this.vocabulary_total) ;
  }

  changeVocabListMode(mode: VocabularyListMode): void {
    this.vocab_list_mode = mode;
  }

  onPageSizeChanged(new_page_size: PageSize): void {
    this.table_query_params.paginate_params.page_size = new_page_size;
    this.table_query_params.paginate_params.page = 0;
    this.table_query_params.invalidateCurrentBuffer(true);
    this.issueQuery(this.table_query_params);
    this.scrollToTopIfNeeded();
  }

  onPageChanged(paginate_operation: PaginateOperation): void {
    this.table_query_params.paginate_params.page += (paginate_operation == "up" ? 1 : -1);
    this.table_query_params.paginate_params.page = Math.max(0,this.table_query_params.paginate_params.page);
    this.issueQuery(this.table_query_params);
    this.scrollToTopIfNeeded();
  }

  @Watch('table_query_params.sort_params.column')
  @Watch('table_query_params.sort_params.order')
  onTableSortParamsChanged(new_val: VSortParams, old_val: VSortParams): void {
    this.table_query_params.invalidateCurrentBuffer(true);
    this.issueQuery(this.table_query_params);
  }

  @Watch('table_search_params.text')
  onTableSearchParamsChanged(): void {
    if(this.table_search_params.mode == "simple") {
      this.last_table_search_params = VocabularyQueryParams.staticCloneSearchParams(this.table_search_params);
      this.table_query_params.invalidateCurrentBuffer(true);
      this.issueQuery(this.table_query_params);
    }
  }

  onSelectionBoxChanged(): void {
    this.vocabulary_query.hasEditedSelection(this.table_query_params.paginate_params.page);
    this.vocabulary_row_list.forEach( (vocabulary_table_row: VocabularyTableRow) => {
      if(vocabulary_table_row.vocabulary_state.selected !== this.selection_box) {
        this.global_selection_count += this.selection_box ? 1 : -1;
      }
      vocabulary_table_row.vocabulary_state.selected = this.selection_box;
    })
    this.local_selection_count = this.selection_box ? this.vocabulary_row_list.length : 0;
  }

/*
  @Watch('table_mode')
  changeTableMode(new_table_mode: VocabTableMode) {
    //this.table_mode = new_table_mode;
  }
*/

/*
  onVocabularyEvent(vocabulary_event: VocabularyEventType): void {
    if(vocabulary_event == "refreshed") {

    } else {

    }
    this.query_requests$.next(this.table_query_params);
  }
*/

  issueQuery(vocabulary_query_params: VocabularyQueryParams): void {
    vocabulary_query_params.generateID();
    if(this.table_search_params.mode == "advanced" && this.last_table_search_params) {
      if(this.liebiao) {
        this.last_table_search_params.liebiaos.push(this.liebiao);
      }
      vocabulary_query_params.addSearchParams(this.last_table_search_params);
    } else if(this.table_search_params.mode == "simple" && this.last_table_search_params) {
      if((this.table_search_params.text && this.table_search_params.text.length > 0 && this.last_table_search_params.text.length > 0) ||
          (!this.table_search_params.text && this.last_table_search_params.text.length > 0)) {
        if(this.liebiao) {
          this.last_table_search_params.liebiaos.push(this.liebiao);
        }
        vocabulary_query_params.addSearchParams(this.last_table_search_params);
      }
    }
    if(this.liebiao && !vocabulary_query_params.search_params) {
      this.last_table_search_params.liebiaos.push(this.liebiao);
      vocabulary_query_params.addSearchParams(this.last_table_search_params);
    }
    //log(vocabulary_query_params);
    this.query_requests$.next(vocabulary_query_params);
  }
  
  onVocabAdded(): void {
    this.refreshVocabulary(false);
  }

  updatePaginateParams(vocabulary_query_results: VocabularyPageResult): void {
    this.table_query_params.paginate_params.page = vocabulary_query_results.page;
  }

  tableModeTitle(): string {
    if (this.table_mode == 'normal') return "Details";
    if (this.table_mode == 'quickstudy') return "Quick study";
    if (this.table_mode == 'compact') return "Compact";
    return "";
  }

  /*
  @Watch('table_swtich')
  onTableModeChanged(new_value: boolean, old_value: boolean) {
    this.table_mode = new_value ? "quickstudy" : "normal";
    if(this.table_mode == 'normal' && (this.$refs.quick_study_table as VocabularyTableQuickStudy)) {
      (this.$refs.quick_study_table as VocabularyTableQuickStudy).dismountTable();
      (this.$refs.quick_study_table as VocabularyTableQuickStudy).resetTable();
    }
  }
  */

 onIntersect(entries: any, observer: any): void {
  this.table_top_visible = entries[0].isIntersecting;
 }

 scrollToTopIfNeeded(): void {
  if(!this.table_top_visible) {
    if(document.getElementById('table-container')) {
      this.$vuetify.goTo("#table-container", {offset:200});
      this.table_top_visible = true; //HACK
    } 
    else console.log('scroll target not found');
  }
 }

  resetQuickStudyTable(goto: boolean = false): void {
    if((this.$refs.quick_study_table as VocabularyTableQuickStudy)) {
      this.vocabulary_row_list.forEach( (vocab_item: VocabularyTableRow) => {
        vocab_item.chinese_visible = !this.reverse_study_mode;
        vocab_item.pinyin_visible = false;
        vocab_item.english_visible = this.reverse_study_mode;
        vocab_item.note_visible = false;
      });
      (this.$refs.quick_study_table as VocabularyTableQuickStudy).resetTable();
      if(goto) {
        this.scrollToTopIfNeeded();
      }
    }
  }

  editTags(): void {
    console.log('new tag!');
  }

  initVocabularyQuery(): void {
    let query_debounced$: Observable<VocabularyQueryParams> = this.query_requests$.pipe(
      debounceTime(500),
      tap((vocabulary_query_params: VocabularyQueryParams) => {
        //console.log('debounced')
    }));
    let query_throttled$: Observable<VocabularyQueryParams> = this.query_requests$.pipe(
      throttle(() => query_debounced$),
      tap((vocabulary_query_params: VocabularyQueryParams) => {
        //console.log('throttled')
      })
    );

    let query_page_result$: Observable<VocabularyPageResult> = merge(query_throttled$, query_debounced$).pipe(
      filter((vocabulary_query_params: VocabularyQueryParams) => {
        if(this.last_table_query_params == null) {
          return true;
        }
        if(vocabulary_query_params.isIdentical(this.last_table_query_params)) {
          return false;
        }
        //console.log('vocabulary_query_params:');
        //log(vocabulary_query_params);
        //console.log('this.last_table_query_params:');
        //log(this.last_table_query_params);
        return true;
      }),
      tap((vocabulary_query_params: VocabularyQueryParams) => {
        //console.log(`page requested: ${vocabulary_query_params.paginate_params.page}`)
        //console.log(`setting query_params:`);
        this.last_table_query_params = vocabulary_query_params.clone();
      }),
      switchMap((vocabulary_query_params: VocabularyQueryParams) => {
        const on_timer$: Observable<number> = vocabulary_query_params.trigger_loader ? of(0) : timer(this.delay_before_loader);
        const off_timer$: Observable<number> = timer((vocabulary_query_params.trigger_loader ? 0 : this.delay_before_loader )+ this.min_loader_duration);
        const showLoadingIndicator$ = merge(
          // ON in X seconds
          on_timer$.pipe( mapTo(true), takeUntil(this.vocabulary_query.page_results$) ),
          // OFF once we receive a result, yet at least Xs
          combineLatest(this.vocabulary_query.page_results$, off_timer$).pipe( mapTo(false) )
        )
        .pipe(
          startWith(false),
          distinctUntilChanged(),
          take(3)
        );

        //this.debug_time = new Date().getTime();
        this.loader_subscription$ = showLoadingIndicator$.subscribe({
          next: (is_loading: boolean) => {
            //const now_time = new Date().getTime();
            //console.log(`val,elapsed: ${is_loading}, ${now_time - this.debug_time}`);
            this.table_loading = is_loading;
          },
          complete: () => {
            //console.log('loader completed');
            this.loader_subscription$?.unsubscribe();
            this.loader_subscription$ = null;
          }
        });
        //console.log('querying...');
        this.vocabulary_query.queryWithBuffer(vocabulary_query_params);
        return this.vocabulary_query.page_results$;
      })
    );
    
    this.query_results_subscription = query_page_result$.subscribe({
      next: (vocabulary_query_results: VocabularyPageResult) => {
        //console.log('results received');
        //console.log(vocabulary_query_results);
        //the result count has changed during pagination
        this.vocabulary_taken = vocabulary_query_results.taken;
        //log(`this.vocabulary_taken: ${this.vocabulary_taken}`);
        this.vocabulary_total = vocabulary_query_results.total;
        this.table_query_params.paginate_params.page = vocabulary_query_results.page;
        this.vocabulary_row_list.length = 0;
        let all_selected: boolean = true;
        this.local_selection_count = 0;
        for(let i=0; i<vocabulary_query_results.vocabulary_data.length; i++) {
          if(vocabulary_query_results.vocabulary_data[i].selected) {
              this.local_selection_count++;  
          } else {
              all_selected = false;
          }
          this.vocabulary_row_list.push({
                vocabulary_state: vocabulary_query_results.vocabulary_data[i],
                chinese_visible: !this.reverse_study_mode,
                pinyin_visible: false,
                english_visible: this.reverse_study_mode,
                note_visible: false,
                audio_hover: false,
                audio_visible: false,
                expanded: false,
            });
        }
        //console.log(this.vocabulary_row_list);
        this.vocabulary_row_list.splice(vocabulary_query_results.vocabulary_data.length);
        if(all_selected) { 
          this.selection_box = true;
        } else  {
          this.selection_box = false;
        }
        if(this.vocabulary_taken <= 0) this.selection_box = false;
        //log(this.table_query_params);
        if(this.table_query_params.store_request) {
          if(this.table_query_params.store_request.id) {
            this.vocabulary_service.broadcastVocabularyEvent({
              owner: this,
              type: "updated",
              store_request: this.table_query_params.store_request
            });
          } else {
            this.vocabulary_service.broadcastVocabularyEvent({
              owner: this,
              type: "added",
            });
          }
        }
        if(this.table_query_params.delete_request) {
          this.pending_delete = false;
          this.vocabulary_service.broadcastVocabularyEvent({
            owner: this,
            type: "deleted",
          })
          if(this.table_query_params.delete_request.vocabulary_ids) {
            this.message_dispatcher.pushNotification(new AppNotification(
              "Vocabulary deleted",
              "toast"
            ));
          } else if(this.table_query_params.delete_request.selected) {
            this.local_selection_count = 0;
            this.global_selection_count = 0;
            this.selection_box = false;
            this.message_dispatcher.pushNotification(new AppNotification(
              "Vocabulary deleted",
              "toast"
            ));
          }
        }
        if(this.table_query_params.search_params) {
          if(this.table_query_params.search_params.mode == "advanced") {
            this.vocabulary_service.broadcastVocabularyEvent({
              owner: this,
              type: "advanced searched",
            });
          }
        }
        if(this.table_query_params.is_refresh) {
          this.local_selection_count = 0;
          this.global_selection_count = 0;
          this.selection_box = false;
        }
        this.resetTableQuery();
      }, 
      error: (error) => {
        console.log('error when receiving results');
        console.log(error);
      },
      complete: () => {
        console.log('COMPLETED');
      }
    });
  }

  initVocabularyEventListeners(): void {
    //console.log('initVocabularyEventListeners');
    this.vocabulary_event_subscription = this.vocabulary_service.vocabulary_events$.subscribe({
      next: (vocabulary_event: VocabularyEvent) => {
        if(vocabulary_event.type == "update" || vocabulary_event.type == "add") {
          if(vocabulary_event.store_request) {
            this.table_query_params.invalidateCurrentBuffer(true);
            this.table_query_params.addStoreRequest(vocabulary_event.store_request);
          }
          this.issueQuery(this.table_query_params);
        }
        else if(vocabulary_event.type == "delete") {
          if(vocabulary_event.delete_request) {
            this.table_query_params.invalidateCurrentBuffer(true);
            this.table_query_params.addDeleteRequest(vocabulary_event.delete_request);
          }
          this.issueQuery(this.table_query_params);
        } else if(vocabulary_event.type == "advanced search") {
          this.table_query_params.invalidateCurrentBuffer(true);
          this.table_query_params.addSearchParams(this.table_search_params);
          const search_params: Nullable<SearchParams> = this.table_query_params.cloneSearchParams();
          if(search_params) {
            this.last_table_search_params = search_params;
          }
          //log(this.table_query_params);
          this.issueQuery(this.table_query_params);
        }
      }
    });
  }

  deleteVocabularies(): void {
    this.pending_delete = true;
    const delete_request: VocabularyDeleteRequest = {
      query_id: this.vocabulary_query.query_id,
      selected: true 
    };
    this.table_query_params.addDeleteRequest(delete_request);
    this.issueQuery(this.table_query_params);
  }

  created(): void {
    //(this.$refs.add_component as VocabularyEditComponent).setOwner(this);
    //this.table_query_params.query_id = this.vocabulary_service.EMPTY_QUERY_ID;
    //wait till we're logged and app is ready before loading
    const app_stage_events$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1)).subscribe({
      next: (app_stage: AppStage) => {
        /*
        const current_route: Route = this.nav_router.getCurrentNavigatable() as Route;
        if(current_route) {
          const liebiao_module: AppModule = this.module_provider.getModule(SHENGCI_MODULES.LIEBIAO);
          if(current_route.name === liebiao_module.name && current_route.params.list_name) {
            this.liebiao_name = current_route.params.list_name;
          }
        }
        */
        this.vocabulary_query = this.vocabulary_service.getVocabularyQuery(this);
        this.initVocabularyQuery();
        this.initVocabularyEventListeners();
        this.issueQuery(this.table_query_params);
      }
    });

    AppMain.app_stage_events$.pipe(take(1)).subscribe({
      next: (app_stage: AppStage) => {
        if(app_stage == "unloading" && this.vocabulary_query) {
          this.cleanUp();
        }
      }
    });
  }

  cleanUp(): void {
    if(this.vocabulary_query && this.vocabulary_query.query_id) this.vocabulary_service.flushQuery(this.vocabulary_query);
    this.query_requests$.complete();
    this.query_results_subscription.unsubscribe();
    this.vocabulary_event_subscription.unsubscribe();
    this.loader_subscription$?.unsubscribe();
  }

  destroyed(): void {
    this.cleanUp();
  }

  unmounted(): void {
    this.cleanUp();
  }

  updated(): void {
    if(this.add_vocabulary_open) {
      if(this.$refs.add_component) {
        (this.$refs.add_component as VocabularyEditComponent).setOwner(this);
      }
    }
  }

}
