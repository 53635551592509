

















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { TagBoss } from '@/services';
import { AppStage } from '@/types';
import AppMain from '@/AppMain'
import { take, first } from 'rxjs/operators';

@Component({
  components: {
    
  }
})
export default class ToogleIconWithTooltip extends Vue
{
    @lazyInject(TYPES.TAGBOSS_INSTANCE)
    private tagboss!: TagBoss;
  
    @Prop({ default: 'This is a tooltip' }) tooltip!: string;
    @Prop({ default: 'mdi-home' }) visible_icon!: string;
    @Prop({ default: 'mdi-magnify' }) hidden_icon!: string;
    @Prop({ default: 'green' }) visible_color!: string;
    @Prop({ default: 'blue' }) hidden_color!: string;
    @Prop({ default: false }) toggle_value!: boolean;
    public readonly open_delay: number = 600;

    /*
    @Emit()
    toggled(event: Event): boolean {
      this.toggle_value = !this.toggle_value;
      return this.toggle_value;
    }
    */

   @Emit()
   clicked(event: Event): Event {
      return event;
    }
}
