





























































































































































































































































































































































import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Component, Prop, Emit, Vue, PropSync, Watch } from 'vue-property-decorator';
import { VOCABULARY_SORT_COLUMN, VOCABULARY_TEXT_SEARCH_COLUMN } from '@/enums';
import { SearchParams, VocabularyManager, VocabularyEvent, Nullable, Tag, Liebiao, VocabularyStatus } from '@/types';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import TagsComboBox from '@/components/shared/TagsComboBox.vue';
import LiebiaoComboBox from '@/components/shared/LiebiaoComboBox.vue';
import { VocabularyService, MessageDispatcher } from '@/services';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import SearchOptionsMenu from '@/components/shared/SearchOptionsMenu.vue';
import StatusComboBox from '@/components/shared/StatusComboBox.vue';
import { log } from '@/helpers/logger';
import { compareComponents, formatToUTC } from '@/helpers/helpers';

//TODO turn this into a simple selection option
type SelectionOption = {id: VOCABULARY_TEXT_SEARCH_COLUMN, title: string, disabled: boolean};
type SSearchParams = SearchParams;
type SVocabularyManager = VocabularyManager;
type SLiebiao = Liebiao;

@Component({
  components: {
    TagsComboBox, AsyncButton, SearchOptionsMenu, LiebiaoComboBox, StatusComboBox
  }
})
export default class AdvancedSearch extends Vue {
  //@Prop({ default: 'Search vocabulary' }) placeholder!: string;
  @PropSync('search_params', { type: Object }) search_params_synced!: SSearchParams;
  @Prop() owner!: SVocabularyManager;
  @Prop() liebiao!: SLiebiao;

  placeholder(): string {
    return `Search ${this.liebiao ? this.liebiao.name : 'vocabulary'}`;
  }

  //public date_from: string = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
  public date_from: string = "";
  public date_to: string = "";
  public modal_from_date: boolean = false;
  public modal_to_date: boolean = false;
  public form_is_valid: boolean = false;
  public submit_pending: boolean = false;
  public disable_form: boolean = false;
  public results_subscription: Nullable<Subscription> = null;

  //public tags_include: Array<Tag> = new Array<Tag>();
  //public tags_exclude: Array<Tag> = new Array<Tag>();

  /*
  statusesChanged(statuses: Array<VocabularyStatus>): void {
    this.search_params_synced.statuses = statuses;
    this.$emit('search_params', this.search_params_synced);
  }
  */
  
  /*
  @Watch('tags_include')
  onTagsIncludeChanged(): void {
    this.search_params_synced.tags_include = this.tags_include; 
    this.$emit('search_params', this.search_params_synced);
  }
  */
  
  /*
  liebiaosChanged(liebiaos: Array<Liebiao>): void {
    this.search_params_synced.liebiaos = liebiaos;
    this.$emit('search_params', this.search_params_synced);
  }
  */
  
  /*
  includeTagsChanged(tags: Array<Tag>): void {
    this.search_params_synced.tags_include = tags; 
    this.$emit('search_params', this.search_params_synced);
  }

  excludeTagsChanged(tags: Array<Tag>): void {
    this.search_params_synced.tags_exclude = tags; 
    this.$emit('search_params', this.search_params_synced);
  }
  */
  
  @lazyInject(TYPES.VOCABULARYSERVICE_INSTANCE)
  private vocabulary_service!: VocabularyService;

  public search_options: Array<SelectionOption> = new Array<SelectionOption>(
    {id:VOCABULARY_TEXT_SEARCH_COLUMN.CHINESE, title: "Chinese", disabled: false},
    {id:VOCABULARY_TEXT_SEARCH_COLUMN.PINYIN, title: "Pinyin", disabled: false},
    {id:VOCABULARY_TEXT_SEARCH_COLUMN.ENGLISH, title: "English", disabled: false},
    {id:VOCABULARY_TEXT_SEARCH_COLUMN.NOTE, title: "Note", disabled: false}
  );
  public regexp_button_color: string = "white";

  @Watch('date_from')
  @Watch('date_to')
  public onDateChanged(): void {
    console.log('onDateChanged');
    if(this.date_to && this.date_to.length > 0) {
      this.search_params_synced.date_to = formatToUTC(this.date_to);
    } else {
      this.search_params_synced.date_to = null;
      this.date_to = "";
    }
    if(this.date_from && this.date_from.length > 0) {
      this.search_params_synced.date_from = formatToUTC(this.date_from);
    } else {
      this.search_params_synced.date_from = null;
      this.date_from = "";
    }
    if(this.date_to || this.date_from) {
      this.$emit('search_params', this.search_params_synced);
    }
  }

  public changeRegexp(): void {
    this.search_params_synced.regexp = !this.search_params_synced.regexp;
    this.regexp_button_color = this.search_params_synced.regexp ? "primary" : "white";
    this.$emit('search_params', this.search_params_synced);
  }

  submitForm(): boolean {
    //NOTE possible bug ,if can submit invalid forms, the issue might be here
    if(!this.$refs.search_form || !this.form_is_valid) return false;

    this.submit_pending = true;
    this.disable_form = true;

    //console.log('submitting form');

    this.vocabulary_service.broadcastVocabularyEvent({
      owner: this.owner,
      type: "advanced search",
    });

    this.results_subscription = this.vocabulary_service.vocabulary_events$.pipe(
      tap((vocabulary_event: VocabularyEvent) => {
        //console.log(vocabulary_event.type);
      }),
      filter((vocabulary_event: VocabularyEvent) => {
        if(vocabulary_event.type == "advanced searched" && compareComponents(vocabulary_event.owner, this.owner)) return true;
        return false;
      }),
    ).subscribe({
      next: (vocabulary_event: VocabularyEvent) => {
        this.submit_pending = false;
        this.disable_form = false;
        this.results_subscription?.unsubscribe();
      }
    });
    return true;
  }

  reset(): void {
    this.date_from = "";
    this.date_to = "";
    this.search_params_synced.regexp = false;
    this.regexp_button_color = this.search_params_synced.regexp ? "primary" : "white";
    this.search_params_synced.text = "";
    this.search_params_synced.search_column = VOCABULARY_TEXT_SEARCH_COLUMN.CHINESE;
    if(this.$refs.tags_include_form as TagsComboBox) (this.$refs.tags_include_form as TagsComboBox).clearSelection();
    if(this.$refs.tags_exclude_form as TagsComboBox) (this.$refs.tags_exclude_form as TagsComboBox).clearSelection();
    if(this.$refs.status_form as StatusComboBox) (this.$refs.status_form as StatusComboBox).clearSelection();
    if(this.$refs.liebiao_form as LiebiaoComboBox) (this.$refs.liebiao_form as LiebiaoComboBox).clearSelection();
    if(this.$refs.search_form) (this.$refs.search_form as HTMLFormElement).resetValidation();
    this.$emit('search_params', this.search_params_synced);
  }

  @Emit()
  public onClose(): void {
    this.reset();
    return;
  }

  public columnIDToTitle(column_id: number): string {
    for(let i=0; i<this.search_options.length; i++) {
      if(this.search_options[i].id == column_id) {
        return this.search_options[i].title;
      }
    }
    return "";
  }

  selectOption(option: SelectionOption): void {
    this.search_params_synced.search_column = option.id;
    this.$emit('search_params', this.search_params_synced);
  }
}
