










































































































































































































































import { Component, Vue, Watch, Prop, Emit, PropSync } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { VocabularyService } from '@/services';
import AppMain from '@/AppMain';
import { take, first } from 'rxjs/operators';
import { VocabularyTableRow, Vocabulary } from '@/types';
import ToogleIconWithTooltip from '@/components/shared/ToogleIconWithTooltip.vue';
import TagChip from '@/components/shared/TagChip.vue';
import AudioButton from '@/components/shared/AudioButton.vue';


@Component({
  components: {
    ToogleIconWithTooltip, TagChip, AudioButton
  }
})
export default class VocabularyTableCompact extends Vue
{  
  @Prop() public vocabulary_row_list!: Array<VocabularyTableRow>;
  @Prop({default:false}) public table_loading!: boolean;
  public chinese_column_visible: boolean = true;
  public expanded_state: boolean = false;

  /*
  expandAll(): void {
    this.expanded_state = !this.expanded_state;
    this.vocabulary_row_list_synced.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.expanded = this.expanded_state;
    });
  }

  toggleChineseColumn(visible: boolean): void {
    this.vocabulary_row_list_synced.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.chinese_visible = visible;
    });
  }

  togglePinyinColumn(visible: boolean): void {
    this.vocabulary_row_list_synced.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.pinyin_visible = visible;
      if(!visible) vocab_item.pe_mode = "pinyin";
    });
  }

  toggleEnglishColumn(visible: boolean): void {
    this.vocabulary_row_list_synced.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.english_visible = visible;
    });
  }
  */
}
