



























































































import { Component, Vue, Watch, Prop, Emit, PropSync } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { VocabularyService } from '@/services';
import AppMain from '@/AppMain';
import { take, first } from 'rxjs/operators';
import { VocabularyTableRow, VocabularyManager } from '@/types';
import ToogleIconWithTooltip from '@/components/shared/ToogleIconWithTooltip.vue';
import VocabularyTableRowNormal from '@/components/shared/VocabularyTableRowNormal.vue';

type SVocabularyManager = VocabularyManager;
type SVocabularyTableRow = VocabularyTableRow;

@Component({
  components: {
    ToogleIconWithTooltip, VocabularyTableRowNormal
  }
})
export default class VocabularyTableNormal extends Vue
{  
  @Prop() public vocabulary_row_list!: Array<SVocabularyTableRow>;
  @Prop({default:false}) public table_loading!: boolean;
  @Prop() public owner!: SVocabularyManager;

  public chinese_column_visible: boolean = true;
  public expanded_state: boolean = false;

  @Emit()
  changeRowSelectionBox(vocabulary_row: SVocabularyTableRow): SVocabularyTableRow {
    return vocabulary_row;
  }

  /*
  expandAll(): void {
    this.expanded_state = !this.expanded_state;
    this.vocabulary_row_list_synced.forEach( (vocabulary_row: VocabularyTableRow) => {
      vocabulary_row.expanded = this.expanded_state;
    });
  }

  toggleChineseColumn(visible: boolean): void {
    this.vocabulary_row_list_synced.forEach( (vocabulary_row: VocabularyTableRow) => {
      vocabulary_row.chinese_visible = visible;
    });
  }

  togglePinyinColumn(visible: boolean): void {
    this.vocabulary_row_list_synced.forEach( (vocabulary_row: VocabularyTableRow) => {
      vocabulary_row.pinyin_visible = visible;
      if(!visible) vocabulary_row.pe_mode = "pinyin";
    });
  }

  toggleEnglishColumn(visible: boolean): void {
    this.vocabulary_row_list_synced.forEach( (vocabulary_row: VocabularyTableRow) => {
      vocabulary_row.english_visible = visible;
    });
  }
  */
}
