var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.statuses_synced)?_c('v-combobox',{class:{combo_box_dense: _vm.dense},attrs:{"items":_vm.status_items,"filled":_vm.filled,"dense":_vm.dense,"outlined":_vm.outlined,"label":_vm.label,"multiple":"","loading":_vm.loading,"disabled":_vm.isDisabled(),"chips":"","clearable":"","menu-props":{maxWidth:500,'offset-y':true},"hide-details":"","prepend-inner-icon":"mdi-school"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',{staticClass:"status-chip my-1 py-1",attrs:{"attrs":attrs,"select":select,"input-value":selected,"close":"","label":"","color":_vm.statusColor(item),"outlined":"","pill":""},on:{"click:close":function($event){return _vm.removeStatus(item)}}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2715892217),model:{value:(_vm.selected_statuses),callback:function ($$v) {_vm.selected_statuses=$$v},expression:"selected_statuses"}}):_c('v-combobox',{class:{combo_box_dense: _vm.dense},attrs:{"items":_vm.status_items,"filled":_vm.filled,"dense":_vm.dense,"outlined":_vm.outlined,"label":_vm.label,"loading":_vm.loading,"disabled":_vm.isDisabled(),"chips":"","menu-props":{maxWidth:500,'offset-y':true},"hide-details":"","prepend-inner-icon":"mdi-school"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',{staticClass:"status-chip my-1 py-1",attrs:{"attrs":attrs,"select":select,"input-value":selected,"label":"","color":_vm.statusColor(item),"outlined":"","pill":""},on:{"click:close":function($event){return _vm.removeStatus(item)}}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.status_synced),callback:function ($$v) {_vm.status_synced=$$v},expression:"status_synced"}})}
var staticRenderFns = []

export { render, staticRenderFns }