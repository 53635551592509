
































































import { Component, Prop, Emit, Vue, Watch, PropSync } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { LiebiaoManager } from '@/services';
import { AppStage, Liebiao, LiebiaoResult, VocabularyStatus } from '@/types';
import AppMain from '@/AppMain';
import { take, first } from 'rxjs/operators';
import TagChip from '@/components/shared/TagChip.vue';
import remove from 'lodash/remove';
import { generateComponentID, generateRequestID } from '@/helpers/helpers';
import { getStatusString, getStatusColor, getStatusTitle } from '@/helpers/StatusHelpers';

@Component({
  components: {
    TagChip
  }
})
export default class StatusComboBox extends Vue
{
    @lazyInject(TYPES.LIEBIAO_MANAGER_INSTANCE)
    private liebiao_manager!: LiebiaoManager;
  
    @Prop({ default:''}) label!: string;
    @Prop({default:false}) disabled!: boolean;
    //@Prop() public default_statuses!: Array<VocabularyStatus>;
    @PropSync('statuses', { type: Array }) statuses_synced!: Array<VocabularyStatus>;
    @PropSync('status', { type: String }) status_synced!: string;
    public multi_mode: boolean = false; 
    @Prop({default: true}) public filled!: boolean;
    @Prop({default: false}) public dense!: boolean;
    @Prop({default: false}) public outlined!: boolean;
    public status_items: Array<VocabularyStatus> = ["unfamiliar" , "learning" , "reviewing" , "maintaining" , "mastered"];
    public selected_statuses: Array<VocabularyStatus> = [];
    public loading: boolean = false;
    public inited: boolean = false;

    statusColor(status: VocabularyStatus): string {
        return getStatusColor(status);
    }
    
    statusTitle(status: VocabularyStatus): string {
        return getStatusTitle(status);
    }

    @Watch('selected_statuses')
    selectedStatusesChanged(statuses: Array<VocabularyStatus>): void {
      this.statuses_synced = statuses;
      this.$emit('statuses', this.statuses_synced);
    }

    isDisabled(): boolean {
      if(this.disabled) return true;
      return false;
    }

    clearSelection(): void {
      if(!this.multi_mode) this.status_synced = "";
      this.selected_statuses.length = 0;
      this.selected_statuses = [...this.selected_statuses];
    }

    removeStatus(status: VocabularyStatus): void {
      if(!this.multi_mode) this.status_synced = "";
      this.selected_statuses.splice(this.selected_statuses.indexOf(status), 1);
      this.selected_statuses = [...this.selected_statuses];
    }

    created(): void {
      if(this.statuses_synced?.length > 0) {
        this.multi_mode = true;
        this.statuses_synced.forEach((status: VocabularyStatus) => {
          this.selected_statuses.push(status);
        });
      }
      //wait till we're logged and app is ready before loading tags
      const app_stage_events$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1)).subscribe({
        next: (app_stage: AppStage) => {
            //
        }
      });
    }
}
