






















































































import { Component, Prop, Emit, Vue, PropSync } from 'vue-property-decorator';
import { TableMode, VocabularyTableRow, PaginateOperation, PageSize } from '@/types';

type SPaginateOperation = PaginateOperation;

@Component({
  components: {
    
  }
})
export default class TablePaginator extends Vue {
  @Prop({ default: 'normal' }) table_mode!: string;
  @Prop({ default: 0 }) total!: number;
  @Prop({ default: 0 }) start!: number;
  @Prop({ default: 0 }) end!: number;
  @Prop({ default: 0 }) page!: number;
  @Prop({ default: 0 }) page_size!: number;
  @Prop() public vocabulary_row_list!: Array<VocabularyTableRow>;
  public readonly page_sizes: Array<PageSize> = [10, 25, 50, 100, 150];
  public formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US'); 

/*
  created(): void {
  }
  */

/*
  pageSize(): number {
    console.log(`getting page size: ${this.page_size}`);
    return this.page_size;
  }
  */

  @Emit()
  changePage(page_change_op: SPaginateOperation): SPaginateOperation {
    return page_change_op;
  }

  @Emit()
  changePageSize(new_page_size: number): number {
    return new_page_size;
  }
}
