

















import { Component, Prop, Emit, Vue, PropSync, Watch } from 'vue-property-decorator';
import { TableMode, SortOrder, SortParams } from '@/types';
import { VOCABULARY_SELECTION_OPTION } from '@/enums';

type TMSTableMode = TableMode;
type VSortParams = SortParams;
type SelectionOption = {id: VOCABULARY_SELECTION_OPTION, title: string, disabled: boolean};

@Component({
  components: {
    
  }
})
export default class SelectionOptionsMenuButton extends Vue {
    @Prop({ default: 0 }) total!: number;
    public selection_options: Array<SelectionOption> = [
      {id:VOCABULARY_SELECTION_OPTION.SELECT_ALL, title:'Select all (0)', disabled: false},
      {id:VOCABULARY_SELECTION_OPTION.SELECT_NONE, title:'Select none', disabled: false},
      {id:VOCABULARY_SELECTION_OPTION.INVERT_SELECTION, title:'Invert Selection', disabled: true},
      {id:VOCABULARY_SELECTION_OPTION.RANDOM_SELECTION, title:'Randomize', disabled: true},
    ];
    public formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US');

    @Watch('total')
    onTotalChanged(): void {
        for(let x=0; x<=this.selection_options.length; x++) {
          if(this.selection_options[x].id == VOCABULARY_SELECTION_OPTION.SELECT_ALL) {
            this.selection_options[x].title = `Select all (${this.formatter.format(this.total)})`;
            break;
          }
        }
    }

    @Emit()
    onSelectionOptionChanged(option: VOCABULARY_SELECTION_OPTION): VOCABULARY_SELECTION_OPTION {
      return option;
    }

/*
  @Emit()
  onclick(event: Event): Event {
    return event;
  }
*/
  /*
  @Emit()
  sortParamsChanged(): VSortParams {
    return {
        column: this.sort_column,
        order: this.order
    };
  }
  */
}
