

















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { TagBoss } from '@/services';
import AppMain from '@/AppMain'
import { take, first } from 'rxjs/operators';
import { InputValidationRule } from 'vuetify';
import { AppURL } from '@/classes/AppURL';
import { FileResourceService } from '@/services';
import { log } from '@/helpers/logger';

@Component({
  components: {
    
  }
})
export default class AudioFileInput extends Vue
{
    @Prop({default: false}) small!: boolean;
    @Prop({default: false}) small_icon!: boolean;
    @Prop({default: false}) disabled!: boolean;
    @Prop({default: "purple"}) color!: string;
    @Prop({default: ""}) left_icon!: string;
    @Prop() audio_file_url!: AppURL;
    public audio_file: File | null = null;
    public loading: boolean = false;

    @lazyInject(TYPES.FILE_RESOURCE_SERVICE_INSTANCE)
    private file_resource_service!: FileResourceService;

    created(): void {
        if(this.audio_file_url && !this.audio_file_url.isEmpty()) {
            this.loading = true;
            this.file_resource_service.fetchFileResource(this.audio_file_url).then((file: File) => {
                this.audio_file = file;
                this.loading = false;
                if(this.audio_file) this.audioFileChanged();
            }).catch((error) => {
                this.loading = false;
            });
        }
    }

    @Emit()
    audioFileChanged(): File | null {
        return this.audio_file;
    }

    clearAudioFile(): void {
        this.audio_file = null;
    }

    public audio_file_rules: Array<InputValidationRule> = [
        (value) => (!value || value.size < 1000000) || 'The file must be less than 1 MB',
    ];
}
