
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import { VocabularyEditor, VocabularyManager } from '@/types';

export default abstract class VocabularyEditComponent extends Vue implements VocabularyEditor
{
    abstract setOwner(vocabulary_manager: VocabularyManager): void;
    abstract owner: VocabularyManager;
}

