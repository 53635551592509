















































































import { Component, Prop, Emit, Vue, PropSync } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { TagBoss } from '@/services';
import { AppStage, SearchMode, SearchParams } from '@/types';
import AppMain from '@/AppMain'
import { take, first } from 'rxjs/operators';

type SSearchMode = SearchMode;
type SSearchParams = SearchParams;

@Component({
  components: {
    
  }
})
export default class SearchBox extends Vue
{
    @lazyInject(TYPES.TAGBOSS_INSTANCE)
    private tagboss!: TagBoss;
  
    @Prop({ default: 'Search...' }) placeholder!: string;
    @PropSync('search_params', { type: Object }) search_params_synced!: SSearchParams;

    public loading: boolean = false;

    @Emit()
    onChangeSearchMode(search_mode: SSearchMode): SSearchMode {
      return search_mode;
    }

    clickMe(): void {
        console.log('');
    }

    /*
    created(): void {
      //wait till we're logged and app is ready before loading tags
      const app_stage_events$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1)).subscribe({
        next: (app_stage: AppStage) => {
          console.log();
        }
      });
    }
    */
}
