

















































































































































































































































































































































































































































































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { take, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Nullable, Vocabulary, Breakpoint, VocabularyTableRow, TableRowMode, VocabularyEvent,VocabularyManager } from '@/types';
import AudioButton from '@/components/shared/AudioButton.vue';
import TagChip from '@/components/shared/TagChip.vue';
import VocabularyEditComponent from '@/components/shared/VocabularyEditComponent.vue';
import { VocabularyService, MessageDispatcher } from '@/services';
import { AppNotification } from '@/classes/AppNotification';
import DeleteVocabularyMenu from '@/components/shared/DeleteVocabularyMenu.vue';

type SVocabulary = Vocabulary;
type SBreakpoint = Breakpoint;
type MouseCoverage = "in" | "out";
type SVocabularyTableRow = VocabularyTableRow;
type SVocabularyManager = VocabularyManager;

@Component({
  components: {
    AudioButton, TagChip, VocabularyEditComponent, DeleteVocabularyMenu
  }
})
export default class VocabularyTableRowNormal extends Vue
{
  @Prop() public vocabulary_row!: SVocabularyTableRow;
  @Prop() public owner!: SVocabularyManager;
  public row_mode: TableRowMode = "normal";
  public menu: boolean = false;
  public pending_delete: boolean = false;
  private vocabulary_events_subscription: Nullable<Subscription> = null;

  @lazyInject(TYPES.VOCABULARYSERVICE_INSTANCE)
  private vocabulary_service!: VocabularyService;

  @lazyInject(TYPES.MESSAGEDISPATCHER_INSTANCE)
  private message_dispatcher!: MessageDispatcher;

  statusColor(): string  {
    switch(this.vocabulary_row.vocabulary_state.vocabulary.status) {
      case "learning":
        return "yellow";
      case "reviewing":
        return "orange";
      case "maintaining":
        return "blue";
      case "mastered":
        return "green";
      default:
      case "unfamiliar":
        return "gray";
    }
  }

  @Emit()
  changeRowSelectionBox(): SVocabularyTableRow {
    return this.vocabulary_row;
  }

  deleteVocabulary(): void {
    this.pending_delete = true;
    this.vocabulary_service.broadcastVocabularyEvent({
      owner: this.owner,
      type: "delete",
      delete_request: {
        query_id: "",
        vocabulary_ids: [this.vocabulary_row.vocabulary_state.vocabulary.id]
      }
    });
  }

  setRowMode(row_mode: TableRowMode): void {
    if(row_mode == "edit") {
      this.row_mode = "edit";
      this.vocabulary_events_subscription = this.vocabulary_service.vocabulary_events$.pipe(
        filter((vocabulary_event: VocabularyEvent) => {
          if(vocabulary_event.type == "updated") {
            if(vocabulary_event.store_request?.id == this.vocabulary_row.vocabulary_state.vocabulary.id) {
              return true;
            }
          }
          return false;
        }),
        take(1)
      ).subscribe({
        next: (vocabulary_event: VocabularyEvent) => {
          this.row_mode = "normal"; 
          if(this.vocabulary_events_subscription) {
            this.vocabulary_events_subscription.unsubscribe();
            this.vocabulary_events_subscription = null;
          }
          this.message_dispatcher.pushNotification(new AppNotification(
            "Vocabulary updated",
            "toast"
          ));
        }
      });
    } else {
      this.row_mode = "normal";
      if(this.vocabulary_events_subscription) {
        this.vocabulary_events_subscription.unsubscribe();
        this.vocabulary_events_subscription = null;
      }
    }
  }

}
