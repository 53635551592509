























import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { TagBoss } from '@/services';
import { AppStage, VocabularyEvent, VocabularyManager } from '@/types';
import AppMain from '@/AppMain'
import { take, first } from 'rxjs/operators';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { VocabularyService, MessageDispatcher } from '@/services';

type SVocabularyManager = VocabularyManager;

@Component({
  components: {
    AsyncButton
  }
})
export default class DeleteVocabularyMenu extends Vue
{
    @lazyInject(TYPES.TAGBOSS_INSTANCE)
    private tagboss!: TagBoss;
  
    @Prop({ default: 'Search' }) placeholder!: string;
    @Prop({ default: false }) pending_delete!: boolean;
    @Prop({ default: false }) single!: boolean;
    @Prop({ default: 0 }) selection!: number;
    @Prop() public owner!: SVocabularyManager;
    public menu: boolean = false;
    public search_term: string = "";
    public formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US');
    public loading: boolean = false;

    @lazyInject(TYPES.VOCABULARYSERVICE_INSTANCE)
    private vocabulary_service!: VocabularyService;

    @Emit()
    onClick(): boolean {
        this.vocabulary_service.vocabulary_events$.pipe(take(1)).subscribe({
            next: (vocabulary_event: VocabularyEvent) => {
                if(vocabulary_event.type == "deleted") {
                    this.menu = false;
                }
            }
        });
        return true;
    }

    created(): void {
      //wait till we're logged and app is ready before loading tags
      const app_stage_events$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1)).subscribe({
        next: (app_stage: AppStage) => {
          console.log();
        }
      });
    }
}
