
















































import { Component, Prop, Emit, Vue, Watch, PropSync } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { TagBoss } from '@/services';
import { AppStage, Tag, TagResult } from '@/types';
import AppMain from '@/AppMain';
import { take, first } from 'rxjs/operators';
import TagChip from '@/components/shared/TagChip.vue';
import remove from 'lodash/remove';
import { generateComponentID, generateRequestID } from '@/helpers/helpers';
import { log } from '@/helpers/logger';

@Component({
  components: {
    TagChip
  }
})
export default class TagsComboBox extends Vue
{
    @lazyInject(TYPES.TAGBOSS_INSTANCE)
    private tagboss!: TagBoss;
  
    @Prop({ default:''}) label!: string;
    @Prop({default:false}) disabled!: boolean;
    @PropSync('tags', {type: Array}) public tags_synched!: Array<Tag>;
    @Prop({default: true}) public filled!: boolean;
    @Prop({default: false}) public dense!: boolean;
    @Prop({default: false}) public outlined!: boolean;
    @Prop({default: false}) public hide_details!: boolean;
    public selected_tag_strings: Array<string> = [];
    public tag_items: Array<Tag> = [];
    public tag_string_items: Array<string> = [];
    public loading: boolean = true;
    public inited: boolean = false;

    @Watch('selected_tag_strings')
    selectedTagStringsChanged(): void {
      this.tags_synched.length = 0;
      this.selected_tag_strings.forEach((tag_string: string) => {
        for(let i=0; i < this.tag_items.length; i++) {
          if(this.tag_items[i].name === tag_string) {
            this.tags_synched.push(this.tag_items[i]);
            break;
          }
        }
      });
      this.onSelectedTagStringsChanged();
    }

    @Watch('tags_synched')
    syncedTagsChanged(): void {
      if(this.tags_synched.length !== this.selected_tag_strings.length) {
        if(this.tags_synched.length <= 0) {
          this.clearSelection();
        } else {
          this.tags_synched.forEach((tag: Tag) => {
            for(let i=0; i < this.tag_items.length; i++) {
              if(this.tag_items[i].name == tag.name) {
                this.selected_tag_strings.push(this.tag_items[i].name);
                break;
              }
            }
          });
        }
      }
    }

    @Emit()
    onSelectedTagStringsChanged(): void {
      this.$emit('tags', this.tags_synched);
    }

    clearSelection(): void {
      this.selected_tag_strings.length = 0;
      this.selected_tag_strings = [...this.selected_tag_strings];
      this.tags_synched.length = 0;
      this.onSelectedTagStringsChanged();
    }

    removeTag(tag_string: string): void {
        this.selected_tag_strings.splice(this.selected_tag_strings.indexOf(tag_string), 1);
        this.selected_tag_strings = [...this.selected_tag_strings];
        remove(this.tags_synched, (in_tag: Tag) => {
          return in_tag.name == tag_string;
        });
        this.onSelectedTagStringsChanged();
    }

    created(): void {
     if(!this.tags_synched) {
      console.error('TagsComboBox must have tags prop supplied');
     }
      //wait till we're logged and app is ready before loading tags
      const app_stage_events$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1)).subscribe({
        next: (app_stage: AppStage) => {
          const get_tags$ = this.tagboss.requestTags({request_id:generateRequestID()}).pipe(take(1)).subscribe({
            next: (tag_result: TagResult) => {
              this.tag_items = [];
              tag_result.tags.forEach((tag: Tag) => {
                this.tag_items.push(tag);
                this.tag_string_items.push(tag.name);
              });
              if(this.tags_synched.length > 0) {
                this.tags_synched.forEach((tag: Tag) => {
                  for(let i=0; i < this.tag_items.length; i++) {
                    if(this.tag_items[i].name == tag.name) {
                      this.selected_tag_strings.push(this.tag_items[i].name);
                      break;
                    }
                  }
                });
              }
              this.loading = false;
            }
          });
        }
      });
    }
}
