




































import { Component, Prop, Emit, Vue, Watch, PropSync } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { LiebiaoManager } from '@/services';
import { AppStage, Liebiao, LiebiaoResult } from '@/types';
import AppMain from '@/AppMain';
import { take, first } from 'rxjs/operators';
import TagChip from '@/components/shared/TagChip.vue';
import remove from 'lodash/remove';
import { generateComponentID, generateRequestID } from '@/helpers/helpers';

@Component({
  components: {
    TagChip
  }
})
export default class LiebiaoComboBox extends Vue
{
    @lazyInject(TYPES.LIEBIAO_MANAGER_INSTANCE)
    private liebiao_manager!: LiebiaoManager;
  
    @Prop({ default:''}) label!: string;
    @Prop({default:false}) disabled!: boolean;
    @Prop({default: true}) public filled!: boolean;
    @Prop({default: false}) public dense!: boolean;
    @Prop({default: false}) public outlined!: boolean;
    @PropSync('liebiaos', { type: Array }) liebiaos_synched!: Array<Liebiao>;
    public selected_liebiao_strings: Array<string> = [];
    public liebiao_items: Array<Liebiao> = [];
    public liebiao_items_strings: Array<string> = [];
    public loading: boolean = true;
    public inited: boolean = false;

    @Watch('selected_liebiao_strings')
    selectedLiebiaoStringsChanged(): void {
      this.liebiaos_synched.length = 0;
      this.selected_liebiao_strings.forEach((liebiao_string: string) => {
        for(let i=0; i < this.liebiao_items.length; i++) {
          if(this.liebiao_items[i].name == liebiao_string) {
            this.liebiaos_synched.push(this.liebiao_items[i]);
            break;
          }
        }
      });
      this.onSelectedLiebiaoStringsChanged();
    }

    @Watch('liebiaos_synched')
    syncedTagsChanged(): void {
      if(this.liebiaos_synched.length !== this.selected_liebiao_strings.length) {
        if(this.liebiaos_synched.length <= 0) {
          this.clearSelection();
        } else {
          this.liebiaos_synched.forEach((liebiao: Liebiao) => {
            for(let i=0; i < this.liebiao_items.length; i++) {
              if(this.liebiao_items[i].name == liebiao.name) {
                this.selected_liebiao_strings.push(this.liebiao_items[i].name);
                break;
              }
            }
          });
        }
      }
    }

    isDisabled(): boolean {
      if(this.disabled) return true;
      return false;
    }

    @Emit()
    onSelectedLiebiaoStringsChanged(): void {
      this.$emit('liebiaos', this.liebiaos_synched);
    }

    clearSelection(): void {
      this.selected_liebiao_strings.length = 0;
      this.selected_liebiao_strings = [...this.selected_liebiao_strings];
      this.liebiaos_synched.length = 0;
      this.onSelectedLiebiaoStringsChanged();
    }

    removeLiebiao(liebiao_string: string): void {
        this.selected_liebiao_strings.splice(this.selected_liebiao_strings.indexOf(liebiao_string), 1);
        this.selected_liebiao_strings = [...this.selected_liebiao_strings];
        remove(this.liebiaos_synched, (in_liebiao: Liebiao) => {
          return in_liebiao.name == liebiao_string;
        });
        this.onSelectedLiebiaoStringsChanged();
    }

    created(): void {
      if(!this.liebiaos_synched) {
        console.error('LiebiaoComboBox must have liebiaos prop supplied');
      }
      //wait till we're logged and app is ready before loading tags
      const app_stage_events$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1)).subscribe({
        next: (app_stage: AppStage) => {
          const get_tags$ = this.liebiao_manager.requestLiebiaos({request_id:generateRequestID()}).pipe(take(1)).subscribe({
            next: (liebiao_result: LiebiaoResult) => {
              this.liebiao_items = [];
              liebiao_result.liebiaos.forEach((liebiao: Liebiao) => {
                this.liebiao_items.push(liebiao);
                this.liebiao_items_strings.push(liebiao.name);
              });
              if(this.liebiaos_synched.length > 0) {
                this.liebiaos_synched.forEach((liebiao: Liebiao) => {
                  for(let i=0; i < this.liebiao_items.length; i++) {
                    if(this.liebiao_items[i].name == liebiao.name) {
                      this.selected_liebiao_strings.push(this.liebiao_items[i].name);
                      break;
                    }
                  }
                });
              }
              this.loading = false;
            }
          });
        }
      });
    }
}
