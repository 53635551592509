




































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { MessageDispatcher } from '@/services';
import { AppStage } from '@/types';
import AppMain from '@/AppMain'
import { take, first } from 'rxjs/operators';
import { AudioState, Nullable, Vocabulary } from '@/types';
import { AppNotification } from '@/classes/AppNotification';

type SVocabulary = Vocabulary;
type MouseCoverage = "in" | "out";
type SFile = File;

@Component({
  components: {
    
  }
})
export default class AudioButton extends Vue
{
    @lazyInject(TYPES.MESSAGEDISPATCHER_INSTANCE)
    private message_dispatcher!: MessageDispatcher;

    @Prop() public vocabulary!: SVocabulary;
    @Prop() public audio_file!: SFile;
    private hovered: boolean = false;
    public hoverable!: boolean;
    //@Prop({default:false}) play_on_hover!: boolean;
    @Prop({default:false}) disabled!: boolean;
    @Prop() button_mode!: boolean;
    @Prop({default:false}) large!: boolean;
    @Prop({default:false}) show_key!: boolean;
    public audio_state: AudioState = "stopped";
    private audio_object: Nullable<HTMLAudioElement> = null;

    mouseCoverageAudio(coverage: MouseCoverage): void {
      this.hovered = coverage == "in";
    }

    buttonTooltip(): string {
      if(this.vocabulary?.audio_file?.file_name) return this.vocabulary.audio_file.file_name;
      if(this.audio_file) return this.audio_file.name;
      return "No audio file";
    }

    @Watch('audio_file')
    onAudioFileChange(new_val: File, old_val: File): void {
      this.audio_state = "stopped";
      if(this.audio_object) this.audio_object.pause();
      this.loadAudio();
    }

    @Watch('vocabulary')
    onVocabularyChanged(): void {
      this.loadAudio();
    }

    public hasAudio(): boolean {
      return (this.vocabulary && !this.vocabulary.audio_file?.isEmpty()) || !!this.audio_file;
    }

    public buttonColor(): string {
      if(!this.hasAudio()) {
        return "grey";
      }
      if(this.audio_state == "playing") {
        return "red";
      }
      return this.hovered ? 'green' : 'primary';
    }

    public unloadAudio(): boolean {
      this.audio_state = "stopped";
      this.audio_object?.pause();
      this.audio_object = null;
      return true;
    }

    private loadAudio(): boolean {
      if(this.audio_file) {
        this.audio_object = new Audio(URL.createObjectURL(this.audio_file));
      } else if(this.vocabulary && !this.vocabulary.audio_file?.isEmpty()) {
        this.audio_object = new Audio(this.vocabulary.audio_file?.getFullURL());
      }
      
      if(this.audio_object) {
        this.audio_object.addEventListener('ended', () => {
          if(this.audio_state !== "stopped") this.audio_state = "stopped"; 
        });
        return true;
      }
      return false;
    }

    playOrStopAudio(): void {
      if(!this.audio_object) this.loadAudio();
      if(!this.audio_object) return;
      if(this.audio_state == "stopped") {
        this.audio_state = "playing";
        this.audio_object.currentTime = 0;
        this.audio_object.autoplay = true;
        this.audio_object.play().catch((error) => {
          if(error.name == "NotAllowedError") {
            this.message_dispatcher.pushNotification(new AppNotification(
                "Your browser has blocked audio from playing. You may need to interact with the page before audio can be played.",
                "sticky"
              ));
          }
          this.audio_state = "stopped";
        });
      } else {
        this.audio_state = "stopped";
        this.audio_object.pause();
      }
    }

    @Emit()
    remove(tag: string): string {
        return tag;
    }
}
