













































































































































































































































































































































































































































































































































import 'reflect-metadata';
import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import AudioButton from '@/components/shared/AudioButton.vue';
import LiebiaoComboBox from '@/components/shared/LiebiaoComboBox.vue';
import StatusComboBox from '@/components/shared/StatusComboBox.vue';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { VocabularyService, MessageDispatcher } from '@/services';
import TagsComboBox from '@/components/shared/TagsComboBox.vue';
import AudioFileInput from '@/components/shared/AudioFileInput.vue';
import { take, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NewVocabularyData, Tag, Nullable, VocabularyEvent, VocabularyManager, VocabularyEditor, VocabularyTableRow, Liebiao, VocabularyStatus } from '@/types';
import { InputValidationRule } from 'vuetify';
import pinyin from "pinyin";
import { AppNotification } from '@/classes/AppNotification';
import VocabularyEditComponent from '@/components/base/VocabularyEditComponent.vue';
import { AppURL } from '@/classes/AppURL';
import { log } from '@/helpers/logger';
import { compareComponents } from '@/helpers/helpers';

type EditMode = "edit" | "new";
type SLiebiao = Liebiao;
type SVocabularyTableRow = VocabularyTableRow;
type SNewVocabularyData = NewVocabularyData;

@Component({
  components: {
    AsyncButton, TagsComboBox, AudioFileInput, AudioButton, LiebiaoComboBox, StatusComboBox
  }
})
export default class VocabularyEditComponent3Column extends VocabularyEditComponent
{  
  @lazyInject(TYPES.VOCABULARYSERVICE_INSTANCE)
  private vocabulary_service!: VocabularyService;

  @lazyInject(TYPES.MESSAGEDISPATCHER_INSTANCE)
  private message_dispatcher!: MessageDispatcher;

  @Prop() public vocabulary_row!: SVocabularyTableRow;
  @Prop({default:"new"}) public mode!: EditMode;
  @Prop() default_liebiao!: SLiebiao;

  public head: string = "";
  public pinyin: string = "";
  public translation_count: number = 2;
  public readonly max_translations: number = 6;
  public english1: string = "";
  public english2: string = "";
  public english3: string = "";
  public english4: string = "";
  public english5: string = "";
  public english6: string = "";
  public note: string = "";
  public tags: Array<Tag> = [];
  public liebiaos: Array<Liebiao> = [];
  public vocabulary_status: VocabularyStatus = "unfamiliar";
  public audio_input_file: Nullable<File> = null;
  public no_quiz = true

  public keep_tags: boolean = false;
  public vocabulary_form_is_valid: boolean = false;
  public pinyin_auto: boolean = true;
  public vocabulary_form_ref!: HTMLFormElement;
  public audio_input!: HTMLInputElement;
  public vocabulary_submit_pending: boolean = false;
  public disable_form: boolean = false;
  public owner!: VocabularyManager;
  public audio_file_name: string = "";
  public vocabulary_events_subscription: Nullable<Subscription> = null;
  
  public head_rules: Array<InputValidationRule> = [
      (head: string) => !!head || 'Enter Chinese text',
      (head: string) => (head && head.length <= 2000) || 'Chinese text must be 2000 characters or less',
      (head: string) => (head && head.trim().length >= 1) || 'Chinese text must be at least 1 character',
  ];
/*
  showLiebiaoComboBox(): boolean {
    return this.vocabularyLiebiaos().length <= 0 && this.default_liebiao_name.length <= 0;
  }
*/

/*
  statusesChanged(statuses: Array<VocabularyStatus>): void {
    this.vocabulary_statuses = statuses;
  }
*/

/*
  liebiaosChanged(liebiaos: Array<Liebiao>): void {
    this.liebiaos = liebiaos;
  }

  tagsChanged(tags: Array<Tag>): void {
    console.log('tagsChanged:');
    log(tags);
    this.tags = tags;
  }
*/

  addButtonTitle(): string {
    return this.mode == 'new' ? (this.default_liebiao ? 'Add To List' : 'Add') : 'Save'
  }

  audioFileURL(): AppURL {
    if(this.vocabulary_row?.vocabulary_state.vocabulary.audio_file) {
      return this.vocabulary_row.vocabulary_state.vocabulary.audio_file;
    }
    return new AppURL("");
  }

/*
  vocabularyTags(): Array<Tag> {
    if(this.vocabulary_row?.vocabulary_state.vocabulary.tags) {
      return this.vocabulary_row.vocabulary_state.vocabulary.tags;
    } 
    return new Array<Tag>();
  }

*/
/*
  vocabularyLiebiaos(): Array<Liebiao> {
    if(this.vocabulary_row?.vocabulary_state.vocabulary.liebiaos) {
      return this.vocabulary_row.vocabulary_state.vocabulary.liebiaos;
    }
    return new Array<Liebiao>();
  }
  */

  created(): void {
    if(this.vocabulary_row) {
      if(this.vocabulary_row.vocabulary_state.vocabulary.status) {
        this.vocabulary_status = this.vocabulary_row.vocabulary_state.vocabulary.status;
      }
      if(this.vocabulary_row.vocabulary_state.vocabulary.tags) {
        this.tags = this.vocabulary_row.vocabulary_state.vocabulary.tags;
      }
      if(this.vocabulary_row.vocabulary_state.vocabulary.liebiaos) {
        this.liebiaos = this.vocabulary_row.vocabulary_state.vocabulary.liebiaos;
      }
      this.head = this.vocabulary_row.vocabulary_state.vocabulary.head.text;
      this.no_quiz = this.vocabulary_row.vocabulary_state.vocabulary.no_quiz;
      this.pinyin = this.vocabulary_row.vocabulary_state.vocabulary.pinyin;
      this.note = this.vocabulary_row.vocabulary_state.vocabulary.note?.content ?? "";
      this.translation_count = 0;
      if(this.vocabulary_row.vocabulary_state.vocabulary.sentences) {
        if(this.vocabulary_row.vocabulary_state.vocabulary.sentences[0]) {
          this.english1 = this.vocabulary_row.vocabulary_state.vocabulary.sentences[0].text;
          this.translation_count++;
        } 
        if(this.vocabulary_row.vocabulary_state.vocabulary.sentences[1]) {
          this.english2 = this.vocabulary_row.vocabulary_state.vocabulary.sentences[1].text;
          this.translation_count++;
        }
        if(this.vocabulary_row.vocabulary_state.vocabulary.sentences[2]) {
          this.english3 = this.vocabulary_row.vocabulary_state.vocabulary.sentences[2].text;
          this.translation_count++;
        }
        if(this.vocabulary_row.vocabulary_state.vocabulary.sentences[3]) {
          this.english4 = this.vocabulary_row.vocabulary_state.vocabulary.sentences[3].text;
          this.translation_count++;
        }
        if(this.vocabulary_row.vocabulary_state.vocabulary.sentences[4]) {
          this.english5 = this.vocabulary_row.vocabulary_state.vocabulary.sentences[5].text;
          this.translation_count++;
        }
        if(this.vocabulary_row.vocabulary_state.vocabulary.sentences[5]) {
          this.english6 = this.vocabulary_row.vocabulary_state.vocabulary.sentences[5].text;
          this.translation_count++;
        }
      }
      //always give at least two empty translations input
      while(this.translation_count < 2 ) {
        this.translation_count++;
      }

    } else {
      if(this.pinyin_auto) {
        this.pinyin = this.makePinyin(this.head);
      }
      this.translation_count = 2;
    }

    this.vocabulary_events_subscription = this.vocabulary_service.vocabulary_events$.pipe(
        filter((vocabulary_event: VocabularyEvent) => {
          if(vocabulary_event.type == "added") {
            if(compareComponents(vocabulary_event.owner, this.owner)) {
              return true;
            }
          }
          return false;
        }),
      ).subscribe({
        next: (vocabulary_event: VocabularyEvent) => {
          this.message_dispatcher.pushNotification(new AppNotification(
            "Vocabulary added",
            "toast"
          ));
          this.reset();
        }
      });
  }

  setOwner(vocabulary_manager: VocabularyManager): void {
    this.owner = vocabulary_manager;
  }

  @Emit()
  onClose(): boolean {
    if((this.$refs.audio_button as Vue) as AudioButton) {
      ((this.$refs.audio_button as Vue) as AudioButton).unloadAudio();
    }
    this.vocabulary_events_subscription?.unsubscribe();
    this.vocabulary_events_subscription = null;
    return true;
  }

  @Emit()
  onVocabAdded(new_vocabulary: SNewVocabularyData): boolean {
    this.vocabulary_service.broadcastVocabularyEvent({
      owner: this.owner,
      type: "add",
      store_request: new_vocabulary
    });
    return true;
  }

  @Emit()
  onVocabUpdate(new_vocabulary: SNewVocabularyData): SNewVocabularyData {
    this.vocabulary_service.broadcastVocabularyEvent({
      owner: this.owner,
      type: "update",
      store_request: new_vocabulary
    })
    return new_vocabulary;
  }

  reset(): void {
    this.head = "";
    this.pinyin = "";
    this.translation_count = 2;
    this.english1 = "";
    this.english2 = "";
    this.english3 = "";
    this.english4 = "";
    this.english5 = "";
    this.english6 = "";
    this.note = "";
    this.tags = [];
    this.vocabulary_status = "unfamiliar";
    this.liebiaos = [];
    this.vocabulary_form_is_valid = false;
    this.audio_input_file = null;
    this.vocabulary_submit_pending = false;
    this.disable_form = false;
    this.audio_file_name = "";
    if(this.$refs.vocabulary_form) (this.$refs.vocabulary_form as HTMLFormElement).resetValidation();
    if(this.$refs.audio_file_input) (this.$refs.audio_file_input as AudioFileInput).clearAudioFile();
  }

  audioFileChanged(new_audio_file: File | null): void {
    if(new_audio_file) {
      this.audio_input_file = new_audio_file;
    } else {
      this.audio_input_file = null;
    }
  }

  mounted(): void {
    this.vocabulary_form_ref = ((this.$refs.vocabulary_form as Vue).$el as HTMLFormElement);
    this.audio_input = (this.vocabulary_form_ref.querySelector('#audio_file_input') as HTMLInputElement);
  }

  @Watch('pinyin_auto')
  pinyin_toggled(val: boolean, old_val: boolean) {
    if(this.pinyin_auto) {
      this.pinyin = this.makePinyin(this.head);
    }
  }

  //TODO debounce this?
  @Watch('head')
  onHeadChanged(val: string, old_val: string): void {
    this.pinyin = this.makePinyin(this.head);
  }

  makePinyin(chinese: string): string {
    let pinyin_string: string = ""
    pinyin(chinese).forEach( (result: Array<string>) => {
      pinyin_string = pinyin_string.concat(result[0]);
    } );
    return pinyin_string;
  }

  submitForm(): boolean {
    //NOTE possible bug ,if can submit invalid forms, the issue might be here
    if(!this.$refs.vocabulary_form || !this.vocabulary_form_is_valid) return false;

    this.vocabulary_submit_pending = true;
    this.disable_form = true;

    const new_vocabulary: SNewVocabularyData = {
      head: this.head,
      no_quiz: this.no_quiz
    };
    if(this.vocabulary_row) {
      new_vocabulary.id = this.vocabulary_row.vocabulary_state.vocabulary.id;
    }
    if(this.pinyin) {
      new_vocabulary.pinyin = this.pinyin;
    }
    if(this.note) {
      new_vocabulary.note = this.note;
    }
    //Translations
    new_vocabulary.translations = new Array<string>();
    if(this.english1.length > 0) new_vocabulary.translations.push(this.english1);
    if(this.english2.length > 0) new_vocabulary.translations.push(this.english2);
    if(this.english3.length > 0) new_vocabulary.translations.push(this.english3);
    if(this.english4.length > 0) new_vocabulary.translations.push(this.english4);
    if(this.english5.length > 0) new_vocabulary.translations.push(this.english5);
    if(this.english6.length > 0) new_vocabulary.translations.push(this.english6);

    if(this.tags.length > 0) {
      new_vocabulary.tags = [];
      this.tags.forEach((tag: Tag) => {
        new_vocabulary.tags?.push(tag.name);
      });
    }
    
    if(this.liebiaos.length > 0) {
      new_vocabulary.liebiaos = [];
      this.liebiaos.forEach((liebiao: Liebiao) => {
        new_vocabulary.liebiaos?.push(liebiao.id);
      });
    }
    else if (this.default_liebiao) {
      new_vocabulary.liebiaos = [this.default_liebiao.id];
    }
      
    new_vocabulary.status = this.vocabulary_status;

    if(this.audio_input_file) {
      new_vocabulary.audio_file = this.audio_input_file;
    }

    new_vocabulary.date = (new Date(Date.now())).toISOString();

    if(this.vocabulary_row) {
      this.onVocabUpdate(new_vocabulary);
    } else {
      this.onVocabAdded(new_vocabulary);
    }
    return true;
  }

  addEnglish(): void {
    this.translation_count++;
  }
}
