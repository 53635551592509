




















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { TableMode } from '@/types';

@Component({
  components: {
    
  }
})
export default class TableSettings extends Vue {
  @Prop({ default: 'normal' }) table_mode!: string;

  @Emit()
  onclick(event: Event): Event {
    console.log('buttonclicked!');
    return event;
  }
}
