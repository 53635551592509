
















































































































































































































































































































































































































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { TagBoss } from '@/services';
import { AppStage } from '@/types';
import AppMain from '@/AppMain'
import { take, first } from 'rxjs/operators';
import { AudioState, Nullable, Vocabulary, Breakpoint } from '@/types';
import AudioButton from '@/components/shared/AudioButton.vue';

type SVocabulary = Vocabulary;
type SBreakpoint = Breakpoint;
type MouseCoverage = "in" | "out";

@Component({
  components: {
    AudioButton
  }
})
export default class VocabTableRowQuickStudy extends Vue
{
  @Prop() public vocabulary!: SVocabulary;
  @Prop({default:true}) public chinese_visible!: boolean;
  @Prop({default:false}) public pinyin_visible!: boolean;
  @Prop({default:false}) public english_visible!: boolean;
  @Prop({default:false}) public note_visible!: boolean;
  @Prop({default:"xl"}) public breakpoint!: SBreakpoint;
  public audio_hover: boolean  = false;
  public selected: boolean = false;

  @Emit()
  clickChinese(): void {
    /*
    if(this.table_nav_mode == "click" && !vocab_row.chinese_visible) {
      vocab_row.chinese_visible = true;
      if(this.$vuetify.breakpoint.smAndDown) {
        vocab_row.english_visible = false;
        vocab_row.pinyin_visible = false;
        vocab_row.note_visible = false;
      }
      this.checkColumnVisibilities(true, this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.smAndDown);
    }
    */
  }

  @Emit()
  clickPinyin(): void {
    /*
    if(!this.pinyin_visible) {
      this.pinyin_visible = true;
      if(this.$vuetify.breakpoint.mdOnly) {
        this.english_visible = false;
        this.note_visible = false;
      } else if(this.$vuetify.breakpoint.smAndDown) {
        this.english_visible = false;
        this.chinese_visible = false;
        this.note_visible = false;
      }
      //this.checkColumnVisibilities(this.$vuetify.breakpoint.smAndDown, true, this.$vuetify.breakpoint.mdAndDown, this.$vuetify.breakpoint.mdAndDown);
    }
    */
    return;
  }

  @Emit()
  clickEnglish(): void {
    /*
    if(!this.english_visible) {
      this.english_visible = true;
      if(this.$vuetify.breakpoint.mdOnly) {
        this.pinyin_visible = false;
        this.note_visible = false;
      } else if(this.$vuetify.breakpoint.smAndDown) {
        this.chinese_visible = false;
        this.pinyin_visible = false;
        this.note_visible = false;
      }
      //this.checkColumnVisibilities(this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.mdAndDown, true, this.$vuetify.breakpoint.mdAndDown);
    }
    */
  }

  @Emit()
  clickNote(): void {
    /*
    if(!this.note_visible) {
      if(this.$vuetify.breakpoint.xlOnly ) {
        this.note_visible = true;
      } else if(this.$vuetify.breakpoint.mdOnly) {
        this.note_visible = true;
        this.pinyin_visible = false;
        this.english_visible = false;
      } else if(this.$vuetify.breakpoint.smAndDown) {
        this.note_visible = true;
        this.english_visible = false;
        this.pinyin_visible = false;
        this.chinese_visible = false;
      } else {
        this.note_visible = !this.note_visible;
      }
      //this.checkColumnVisibilities(this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.mdAndDown, this.$vuetify.breakpoint.mdAndDown, true);
    }
    */
  }
}
