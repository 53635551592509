












































































































































































































































































import { Component, Vue, Watch, Prop, Emit, PropSync } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { VocabularyService } from '@/services';
import AppMain from '@/AppMain';
import { take, first } from 'rxjs/operators';
import { VocabularyTableRow, TableNavigationMode, TableMode, Nullable, Vocabulary } from '@/types';
import ToogleIconWithTooltip from '@/components/shared/ToogleIconWithTooltip.vue';
import AudioButton from '@/components/shared/AudioButton.vue';
import VocabularyTableRowQuickStudy from '@/components/shared/VocabularyTableRowQuickStudy.vue';
import TagChip from '@/components/shared/TagChip.vue';
import { Subscription } from 'rxjs';
import keyboard_keys, { KeyboardKey } from '@/KeyboardKeys';

type MouseCoverage = "in" | "out";
type QSTableNavigationMode = TableNavigationMode;

@Component({
  components: {
    ToogleIconWithTooltip, TagChip, AudioButton, VocabularyTableRowQuickStudy
  }
})
export default class VocabularyTableQuickStudy extends Vue
{  
  @Prop() public vocabulary_row_list!: Array<VocabularyTableRow>;
  @Prop() public reverse_study_mode!: boolean;
  public chinese_column_visible: boolean = false;
  public pinyin_column_visible: boolean = false;
  public english_column_visible: boolean = false;
  public note_column_visible: boolean = false;
  @Prop({default:"click"}) public table_nav_mode!: QSTableNavigationMode;
  @Prop({default:false}) public table_loading!: boolean;

  @Watch('table_nav_mode')
  OnTableNavModeChange(nav_mode: string, old_nav_mode: string) {
    //this.resetTable();
  }
  
  //TODO this loops through the vocab array twice!
  @Watch('reverse_study_mode')
  OnReverseStudyMode(new_value: boolean, old_value: boolean) {
    this.vocabulary_row_list.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.english_visible = this.reverse_study_mode;
      vocab_item.chinese_visible = !this.reverse_study_mode;
      vocab_item.pinyin_visible = false;
      vocab_item.note_visible = false;
    });
    this.chinese_column_visible = !this.reverse_study_mode;
    this.pinyin_column_visible = false;
    this.english_column_visible = this.reverse_study_mode;
    this.note_column_visible = false;
    this.updateColumnVisibilities(true, true, true, true);
  }

  created(): void {
    this.updateColumnVisibilities(true, true, true, true);
  }

  dismountTable(): void {
    console.log('dismounting table');
  }

  private onQuickStudyCompleted(): void {
    console.log('quick study completed');
  }

  updateColumnVisibilities(chinese: boolean = false, pinyin: boolean = false, english: boolean = false, note: boolean = false): void {
    let all_chinese_visible: boolean = true;
    let all_pinyin_visible: boolean = true;
    let all_english_visible: boolean = true;
    let all_note_visible: boolean = true;
    let chinese_check_finish: boolean = !chinese;
    let pinyin_check_finish: boolean = !pinyin;
    let english_check_finish: boolean = !english;
    let note_check_finish: boolean = !note;
    //start checking from the end since users are likely to go from top to bottom
    for(let x =  this.vocabulary_row_list.length-1; x >= 0; x--) {
      if(chinese && !this.vocabulary_row_list[x].chinese_visible) all_chinese_visible = false;
      if(pinyin && this.vocabulary_row_list[x].vocabulary_state.vocabulary.pinyin.length && !this.vocabulary_row_list[x].pinyin_visible) all_pinyin_visible = false;
      if(english && this.vocabulary_row_list[x].vocabulary_state.vocabulary.sentences?.length && !this.vocabulary_row_list[x].english_visible) all_english_visible = false;
      //if(note && this.vocabulary_row_list[x].vocabulary_state.vocabulary.note.content.length && !this.vocabulary_row_list[x].note_visible) all_note_visible = false;
      if(note && !this.vocabulary_row_list[x].note_visible) all_note_visible = false;
      if(chinese && !all_chinese_visible) chinese_check_finish = true;
      if(pinyin && !all_pinyin_visible) pinyin_check_finish = true;
      if(english && !all_english_visible) english_check_finish = true;
      if(note && !all_note_visible) note_check_finish = true;
      if(chinese_check_finish && pinyin_check_finish && english_check_finish && note_check_finish) break;
    }
    if(chinese) this.chinese_column_visible = all_chinese_visible;
    if(pinyin) this.pinyin_column_visible = all_pinyin_visible;
    if(english) this.english_column_visible = all_english_visible;
    if(note) this.note_column_visible = all_note_visible;
  }

  resetTable(): void {
    this.chinese_column_visible = false;
    this.pinyin_column_visible = false;
    this.english_column_visible = false;
    this.note_column_visible = false;
    this.updateColumnVisibilities(true, true, true, true);
  }

  mouseCoverageChinese(coverage: MouseCoverage, vocab_row: VocabularyTableRow): void {
    console.log('mouseCoverageChinese');
    /*
    if(this.table_nav_mode == "hover") {
      if(!vocab_row.chinese_visible && coverage == "in") {
        vocab_row.chinese_visible =  true;
        if(this.$vuetify.breakpoint.smAndDown) {
          vocab_row.english_visible = false;
          vocab_row.pinyin_visible = false;
          vocab_row.note_visible = false;
        }
        this.checkColumnVisibilities(true, this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.smAndDown);
      }
    }
    */
  }

  mouseCoverageAudio(coverage: MouseCoverage, vocab_row: VocabularyTableRow): void {
    console.log('mouseCoverageAudio');
    vocab_row.audio_hover = coverage == "in" ? true : false;
  }

  mouseCoveragePinyin(coverage: MouseCoverage, vocab_row: VocabularyTableRow): void {
    if(this.table_nav_mode == "hover") {
      if(!vocab_row.pinyin_visible && coverage == "in") {
        vocab_row.pinyin_visible =  true;
        if(this.$vuetify.breakpoint.mdOnly) {
          vocab_row.english_visible = false;
          vocab_row.note_visible = false;
        } else if(this.$vuetify.breakpoint.smAndDown) {
          vocab_row.english_visible = false;
          vocab_row.chinese_visible = false;
          vocab_row.note_visible = false;
        }
        this.updateColumnVisibilities(this.$vuetify.breakpoint.smAndDown, true, this.$vuetify.breakpoint.mdAndDown, this.$vuetify.breakpoint.mdAndDown);
      }
    }
  }

  mouseCoverageEnglish(coverage: MouseCoverage, vocab_row: VocabularyTableRow): void {
    if(this.table_nav_mode == "hover") {
      if(!vocab_row.english_visible && coverage == "in") {
        vocab_row.english_visible =  true;
        if(this.$vuetify.breakpoint.mdOnly) {
          vocab_row.pinyin_visible = false;
          vocab_row.note_visible = false;
        } else if(this.$vuetify.breakpoint.smAndDown) {
          vocab_row.chinese_visible = false;
          vocab_row.pinyin_visible = false;
          vocab_row.note_visible = false;
        }
        this.updateColumnVisibilities(this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.mdAndDown, true, this.$vuetify.breakpoint.mdAndDown);
      }
    }
  }

  mouseCoverageNote(coverage: MouseCoverage, vocab_row: VocabularyTableRow): void {
    if(this.table_nav_mode == "hover") {
      if(!vocab_row.note_visible && coverage == "in") {
        if(this.$vuetify.breakpoint.xlOnly ) {
          vocab_row.note_visible =  true;
        } else if(this.$vuetify.breakpoint.lgOnly) {
          vocab_row.note_visible = !vocab_row.note_visible;
        } else if(this.$vuetify.breakpoint.mdOnly) {
          vocab_row.note_visible =  true;
          vocab_row.pinyin_visible = false;
          vocab_row.english_visible = false;
        } else if(this.$vuetify.breakpoint.smAndDown) {
          vocab_row.note_visible =  true;
          vocab_row.english_visible = false;
          vocab_row.pinyin_visible = false;
          vocab_row.chinese_visible = false;
        }
        this.updateColumnVisibilities(this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.mdAndDown, this.$vuetify.breakpoint.mdAndDown, true);
      }
    }
  }

  clickChinese(vocab_row: VocabularyTableRow): void {
    if(!vocab_row.chinese_visible) {
      vocab_row.chinese_visible = true;
      if(this.$vuetify.breakpoint.smAndDown) {
        vocab_row.english_visible = false;
        vocab_row.pinyin_visible = false;
        vocab_row.note_visible = false;
      }
      this.updateColumnVisibilities(true, this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.smAndDown);
    }
    
  }

  clickPinyin(vocabulary_row: VocabularyTableRow): void {
    if(!vocabulary_row.pinyin_visible) {
      vocabulary_row.pinyin_visible = true;
      if(this.$vuetify.breakpoint.mdOnly) {
        vocabulary_row.english_visible = false;
        vocabulary_row.note_visible = false;
      } else if(this.$vuetify.breakpoint.smAndDown) {
        //console.log('here2');
        vocabulary_row.english_visible = false;
        vocabulary_row.chinese_visible = false;
        vocabulary_row.note_visible = false;
      }
      this.updateColumnVisibilities(this.$vuetify.breakpoint.smAndDown, true, this.$vuetify.breakpoint.mdAndDown, this.$vuetify.breakpoint.mdAndDown);
    }
  }

  clickEnglish(vocab_row: VocabularyTableRow): void {
    if(!vocab_row.english_visible) {
      vocab_row.english_visible = true;
      if(this.$vuetify.breakpoint.mdOnly) {
        vocab_row.pinyin_visible = false;
        vocab_row.note_visible = false;
      } else if(this.$vuetify.breakpoint.smAndDown) {
        vocab_row.chinese_visible = false;
        vocab_row.pinyin_visible = false;
        vocab_row.note_visible = false;
      }
      this.updateColumnVisibilities(this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.mdAndDown, true, this.$vuetify.breakpoint.mdAndDown);
    }
  }

  clickNote(vocab_row: VocabularyTableRow): void {
    if(!vocab_row.note_visible) {
      if(this.$vuetify.breakpoint.xlOnly ) {
        vocab_row.note_visible = true;
      } else if(this.$vuetify.breakpoint.mdOnly) {
        vocab_row.note_visible = true;
        vocab_row.pinyin_visible = false;
        vocab_row.english_visible = false;
      } else if(this.$vuetify.breakpoint.smAndDown) {
        vocab_row.note_visible = true;
        vocab_row.english_visible = false;
        vocab_row.pinyin_visible = false;
        vocab_row.chinese_visible = false;
      } else {
        vocab_row.note_visible = !vocab_row.note_visible;
      }
      this.updateColumnVisibilities(this.$vuetify.breakpoint.smAndDown, this.$vuetify.breakpoint.mdAndDown, this.$vuetify.breakpoint.mdAndDown, true);
    }
  }

  toggleChineseColumn(): void {
    this.chinese_column_visible = !this.chinese_column_visible;
    this.vocabulary_row_list.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.chinese_visible = this.chinese_column_visible;
    });
  }

  togglePinyinColumn(visible: boolean): void {
    this.pinyin_column_visible = visible;
    this.vocabulary_row_list.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.pinyin_visible = this.pinyin_column_visible;
      if(this.pinyin_column_visible && this.$vuetify.breakpoint.mdAndDown) {
        vocab_item.english_visible = false;
        vocab_item.note_visible = false;
      }
    });
    if(this.pinyin_column_visible && this.$vuetify.breakpoint.mdAndDown) {
      this.english_column_visible = false;
      this.note_column_visible = false;
    }
  }

  toggleEnglishColumn(visible: boolean): void {
    this.english_column_visible = visible;
    this.vocabulary_row_list.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.english_visible = this.english_column_visible;
      if(this.english_column_visible && this.$vuetify.breakpoint.mdAndDown) {
        vocab_item.pinyin_visible = false;
        vocab_item.note_visible = false;
      }
    });
    if(this.english_column_visible && this.$vuetify.breakpoint.mdAndDown) {
      this.pinyin_column_visible = false;
      this.note_column_visible = false;
    }
  }

  toggleNoteColumn(visible: boolean): void {
    this.note_column_visible = visible;
    this.vocabulary_row_list.forEach( (vocab_item: VocabularyTableRow) => {
      vocab_item.note_visible = this.note_column_visible;
      if(this.note_column_visible && this.$vuetify.breakpoint.mdAndDown) {
        vocab_item.pinyin_visible = false;
        vocab_item.english_visible = false;
      }
    });
    if(this.note_column_visible && this.$vuetify.breakpoint.mdAndDown) {
      this.pinyin_column_visible = false;
      this.english_column_visible = false;
    }
  }
  
}
